import React from 'react';
import { Typography, Breadcrumbs, Box } from '@material-ui/core';
import { Link } from 'components';
import { FeedbackContent } from 'components/feeback';
import { AppPage } from 'components/layout';

export default function BaselineFeedbackPage() {
  return (
    <AppPage title="All baseline survey feedback">
      <Box mt={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <Typography color="textPrimary">All Baseline Survey Feedback</Typography>
        </Breadcrumbs>
      </Box>
      <FeedbackContent surveyIds={['s1-confidence', 's2-pradas', 's3-k6', 's4-rcads']} />
    </AppPage>
  );
}
